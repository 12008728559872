<script setup lang="ts">
import { capitalize } from 'lodash'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { VSection, VLoadingSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { times, toNumber, initialMoney } from '@/modules/shared/utils/money'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import VSelect from '../components/v-select.vue'
import { useCapitalAccountStore } from '../stores/capital-account-store'
import { generateBookValue, generateSinceInceptionBookValue } from '../utils/use-capital-account'

const { n, t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const skeleton = ref(true)
const authStore = useAuthStore()
const investorStore = useInvestorStore()
const capitalAccountStore = useCapitalAccountStore()

const transfers = computed(() =>
  capitalAccountStore.transfers.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
)
// TODO: filter transfers by investors
const filteredTransfers = computed(() =>
  transfers.value.filter((transfer) => investorStore.state.selectedKeys.includes(transfer.investor._cid)),
)
const bookValueYearOptions = computed(() => {
  let startYear = new Date().getFullYear()
  let endYear = new Date().getFullYear()

  if (transfers.value.length > 0) {
    startYear = new Date(transfers.value[0].date).getFullYear()
    endYear = new Date(transfers.value[transfers.value.length - 1].date).getFullYear()
  }

  return { startYear, endYear }
})
const bookValue = computed(() => generateBookValue(filteredTransfers.value, bookValueYearOptions.value))
const sinceInceptionBookValue = computed(() => generateSinceInceptionBookValue(bookValue.value))

////////////////////////////////////////
//// FILTER
////////////////////////////////////////
const currentYear = new Date().getFullYear().toString()
const selectedYear = ref(currentYear)
const yearOptions = computed(() =>
  Array.from(
    { length: bookValueYearOptions.value.endYear - bookValueYearOptions.value.startYear + 1 },
    (_, i) => bookValueYearOptions.value.startYear + i,
  )
    .concat([parseInt(currentYear)])
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => b - a)
    .map((year) => ({ label: year, value: year })),
)

////////////////////////////////////////
//// TABLE CONFIG
////////////////////////////////////////
const bookValueQuarters = ['Q4', 'Q3', 'Q2', 'Q1']
const rows = [
  {
    label: capitalize(t('shared.beginning balance')),
    key: 'beginning_balance',
  },
  {
    label: capitalize(t('shared.contribution', 0)),
    key: 'total_called',
    break: true,
  },
  {
    label: capitalize(t('shared.distribution', 0)),
    key: 'distributed_capital',
  },
  {
    label: 'Total cash / deemed flows',
    key: 'total_cash',
  },
  {
    label: capitalize(t('shared.management fee', 0)),
    key: 'called_management_fees',
    break: true,
  },
  {
    label: capitalize(t('shared.other called fee', 0)),
    key: 'called_other_fees',
  },
  {
    label: 'Realized gain/loss',
    key: 'realized_gain',
  },
  {
    label: capitalize(t('shared.interest earned')),
    key: 'distributed_interest_earned',
  },
  {
    label: capitalize(t('shared.carried interest')),
    key: 'distributed_carried_interest',
  },
  {
    label: capitalize(t('shared.other distributed fee', 0)),
    key: 'distributed_other_fees',
  },
  {
    label: capitalize(t('shared.ending balance')),
    key: 'ending_balance',
    break: true,
  },
]
const formatMoney = (money) => {
  money ||= initialMoney
  const money_number = toNumber(money)
  if (money_number < 0) return `(${n(times(money, -1), 'currency')})`
  return n(money, 'currency')
}

onMounted(async () => {
  if (!authStore.is_site_admin) router.push({ name: 'investing.entity-overview', params: route.params })

  await Promise.all([
    capitalAccountStore.fetchTransfers(route.params.entity_type, route.params.entity_id),
    investorStore.listProfiles(),
  ])

  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <VSection label="Capital Account" class="mt-3">
      <template #actions>
        <VSelect v-model="selectedYear" :options="yearOptions" :v$="{}" :customLabel="true" size="sm">
          <div class="pr-5">Filter by year {{ selectedYear }}</div>
        </VSelect>
      </template>
      <div class="relative z-0 w-full overflow-x-auto pb-5" v-if="!skeleton">
        <table class="w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <td></td>
              <td>
                <div class="font-bold">Since Inception</div>
              </td>
              <td>
                <div class="font-bold" v-if="currentYear.toString() === selectedYear?.toString()">Year to date</div>
                <div class="font-bold" v-else>{{ selectedYear }}</div>
                <div class="text-sm text-gray-500">Year {{ selectedYear - bookValueYearOptions.startYear + 1 }}</div>
              </td>
              <td v-for="(quarter, i) in bookValueQuarters">
                <div class="flex justify-between">
                  <div class="font-bold">{{ quarter }}</div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows">
              <td :class="{ break: row.break }">{{ row.label }}</td>
              <td :class="{ break: row.break }">
                <template v-if="['beginning_balance', 'ending_balance'].includes(row.key)">
                  {{ formatMoney(sinceInceptionBookValue[row.key]) }}
                </template>
                <template v-else>
                  {{ formatMoney(sinceInceptionBookValue['transaction_summary'][row.key]) }}
                </template>
              </td>
              <td :class="{ break: row.break }">
                <template v-if="['beginning_balance', 'ending_balance'].includes(row.key)">
                  {{ formatMoney(bookValue[selectedYear][row.key]) }}
                </template>
                <template v-else>
                  {{ formatMoney(bookValue[selectedYear]['transaction_summary'][row.key]) }}
                </template>
              </td>
              <td :class="{ break: row.break }" v-for="quarter in bookValueQuarters">
                <template v-if="['beginning_balance', 'ending_balance'].includes(row.key)">
                  {{ formatMoney(bookValue[selectedYear][quarter][row.key]) }}
                </template>
                <template v-else>
                  {{ formatMoney(bookValue[selectedYear][quarter]['transaction_summary'][row.key]) }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <VLoadingSection
        class="mt-40"
        title="Loading Your Capital Account Information..."
        description="Please wait while we retrieve and process your capital account details. This ensures the latest data is available for your review."
        v-else
      />
    </VSection>
  </TheLayout>
</template>

<style scoped>
table thead tr td:nth-child(1),
table tbody tr td:nth-child(1) {
  @apply sticky left-0 z-10 w-[250px] min-w-[250px] max-w-[250px] bg-white !text-left;
}
table thead tr td {
  @apply h-[100px] border-t-[1px] border-gray-300 align-top;
}
table thead tr td:nth-child(1) {
  @apply !border-t-0;
}
table tbody tr td {
  @apply border-b-[1px] border-gray-300 text-right align-top;
}
table tr td {
  @apply min-w-[150px] border-r-[1px] border-gray-300 px-2 py-2;
}
table tr td.break {
  @apply !pt-10;
}
table tr td.close-column {
  @apply !w-[1%] !min-w-[50px] bg-gray-200;
}
</style>
