<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { VButton, VButtonInvisible, VIcon, VLoadingSection } from '@/modules/shared/components'
import CapitalCallForm from '../components/capital-call-form.vue'
import { useCapitalCallStore } from '../stores/capital-call-store'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const skeleton = ref(true)
const capitalCallStore = useCapitalCallStore()
const capital_call = ref(null)
const loading = ref(false)

const submit = async (payload) => {
  loading.value = true
  await capitalCallStore.addCapitalCall(payload)
  router.push({ name: 'investing.calls' })
}

// The function to trigger the child's submit event
const childComponent = ref(null)

// Trigger the child's submit method
const triggerChildSubmit = () => {
  // Use the ref to call the child's submit method
  if (childComponent.value) {
    childComponent.value.submit() // Call the child's exposed function
  }
}

onMounted(async () => {
  await capitalCallStore.fetchCapitalCall(route.params.entity_type, route.params.entity_id)
  capital_call.value = capitalCallStore.items.get('undefined:null') // this is the cid if id is null
  skeleton.value = false
})
</script>

<template>
  <div>
    <!-- nav -->
    <div
      class="sticky top-0 z-50 flex items-center justify-between border-b bg-white/50 px-6 py-4 shadow-sm backdrop-blur"
    >
      <div class="left flex items-center space-x-3">
        <RouterLink
          :to="{
            name: 'investing.calls',
            params: { entity_id: route.params.entity_id, entity_type: route.params.entity_type },
          }"
        >
          <VButtonInvisible><VIcon name="x" /></VButtonInvisible>
        </RouterLink>
        <h3 class="font-medium leading-7 text-gray-700 dark:text-gray-300">Call capital</h3>
      </div>
      <div class="right flex items-center space-x-1.5">
        <RouterLink
          :to="{
            name: 'investing.calls',
            params: { entity_id: route.params.entity_id, entity_type: route.params.entity_type },
          }"
        >
          <VButton>Cancel</VButton>
        </RouterLink>
        <VButton :loading="loading" variant="primary" class="px-8" @click="triggerChildSubmit">Save call</VButton>
      </div>
    </div>
    <!-- main form -->
    <div class="p-6">
      <VLoadingSection
        v-if="skeleton"
        class="mt-40"
        title="Preparing Your Form..."
        description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs
      smoothly."
      />
      <CapitalCallForm v-else ref="childComponent" :capital_call="capital_call" @submit="submit" />
    </div>
  </div>
</template>
