<script setup lang="ts">
import {
  ActionItem,
  ActionsGroup,
  ActionsMenu,
  VBadge,
  VButton,
  VIcon,
  VLink,
  VSection,
  VTable,
} from '@/modules/shared/components'
import CallsBarChart from './calls-bar-chart.vue'
import { capitalize, get } from 'lodash'
import { useCallData } from '../composables/use-call-data'
import { computed, ref } from 'vue'
import { initialMoney } from '@/modules/shared/utils/money'
import { useI18n } from 'vue-i18n'
import { rails_url } from '@/modules/shared/utils/rails'
import { useCallStore } from '../stores/call-store'
import { useRoute } from 'vue-router'
import { useActionsMenu } from '@/modules/shared/utils/actions-menu'
import { useInvoiceStore } from '../stores/invoice-store'

const { t } = useI18n()
const route = useRoute()

const props = withDefaults(
  defineProps<{
    calls: any[]
    chartData: any
    currency?: string
    disabledColumns?: string[]
    investable_id: string
    investable_type: string
    isAdmin: boolean
    skeleton: boolean
  }>(),
  {
    disabledColumns: [],
  },
)

const callStore = useCallStore()
const invoiceStore = useInvoiceStore()
const isAdmin = computed(() => props.isAdmin)
const actionsMenu = useActionsMenu(['send_reminder', 'delete'])

const selectedCallType = ref('all')
const selectCallType = (type: string) => {
  selectedCallType.value = type
}

const current_calls = computed(() =>
  props.calls.filter((call) => {
    if (selectedCallType.value === 'all') {
      return true
    }

    if (selectedCallType.value === 'sent') {
      return call.status === 'sent'
    }

    if (selectedCallType.value === 'scheduled') {
      return call.status === 'unsent'
    }
  }),
)

interface Transaction {
  id: number
  date: string
  status: 'sent' | 'unsent'
  type: string
  investor: {
    id: number
    name: string
    type: string
  }
  transaction: {
    id: number
  }
}

function type(call: Transaction) {
  if (call.type === 'call' && call.status === 'unsent') {
    return 'scheduled call'
  }

  return call.type
}

const removeCall = async (item) => {
  if (type(item) === 'transfer call') return
  if (!window.confirm('Are you sure?')) return
  actionsMenu.setLoading('delete', true)
  await callStore.removeCall(item.id, props.investable_type, props.investable_id)
  actionsMenu.setLoading('delete', false)
}

const sendReminder = async (call) => {
  actionsMenu.setLoading('send_reminder', true)
  await invoiceStore.sendReminder(get(call, 'invoice.id'))
  actionsMenu.setLoading('send_reminder', false)
}
</script>

<template>
  <VSection>
    <div class="-mt-7 mb-5 flex items-center justify-between">
      <div class="flex items-center space-x-1.5" v-if="isAdmin">
        <VButton :active="selectedCallType === 'all'" class="w-32" @click="selectCallType('all')">
          <span>{{ capitalize(t('shared.all')) }}</span>
        </VButton>
        <VButton :active="selectedCallType === 'sent'" class="w-32" @click="selectCallType('sent')">
          <span>{{ capitalize(t('shared.sent')) }}</span>
        </VButton>
        <VButton :active="selectedCallType === 'scheduled'" class="w-32" @click="selectCallType('scheduled')">
          <span>{{ capitalize(t('shared.scheduled')) }}</span>
        </VButton>
      </div>
      <div class="flex items-center space-x-1.5">
        <slot name="actions"></slot>
      </div>
    </div>
    <CallsBarChart
      :currency="currency || 'USD'"
      :committed="chartData.committed"
      :committed_remaining="chartData.committed_remaining"
      :called_received="chartData.called_received"
      :called_received_capital="chartData.called_received_capital"
      :called_received_management_fees="chartData.called_received_management_fees"
      :called_received_other_fees="chartData.called_received_other_fees"
      :called_pending="chartData.called_pending"
      :called_pending_capital="chartData.called_pending_capital"
      :called_pending_management_fees="chartData.called_pending_management_fees"
      :called_pending_other_fees="chartData.called_pending_other_fees"
      :skeleton="skeleton"
    />
  </VSection>
  <VTable
    :columns="[
      {
        key: 'investor.name',
        name: capitalize(t('shared.investor')),
        type: 'string',
        align: 'left',
        fixed: true,
        is_visible: true,
      },
      {
        key: 'entity.name',
        name: capitalize(t('shared.entity')),
        type: 'string',
        align: 'left',
        is_visible: !disabledColumns.some((key) => ['entity.name'].includes(key)),
      },
      {
        key: 'date',
        name: capitalize(t('shared.due date')),
        sorted: true,
        type: 'date',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'call_type',
        name: capitalize(t('shared.type')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'capital',
        name: capitalize(t('shared.capital')),
        type: 'currency',
        aggregate: 'sum',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'management_fee',
        name: capitalize(t('shared.management fee')),
        type: 'currency',
        aggregate: 'sum',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'other_fee',
        name: capitalize(t('shared.other fee')),
        type: 'currency',
        aggregate: 'sum',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total',
        name: capitalize(t('shared.total')),
        type: 'currency',
        aggregate: 'sum',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'invoice.display_status',
        name: capitalize(t('shared.invoice status')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ]"
    :items="current_calls"
    :name="`${investable_type}-${investable_id}-calls`"
    :skeleton="skeleton"
    :slots="['actions', 'call_type', 'entity.name', 'invoice.display_status', 'investor.name']"
  >
    <template #investor.name="{ item }">
      <RouterLink
        class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
        :to="
          get(item, 'investor.ruby_type') === 'fundingentity'
            ? { name: 'investing.other-entity.overview', params: { other_entity_id: get(item, 'investor.id') } }
            : { name: 'investing.individual-overview', params: { individual_id: get(item, 'investor.id') } }
        "
      >
        {{ get(item, 'investor.name') }}
      </RouterLink>
    </template>
    <template #entity.name="{ item }">
      <VLink
        class="hyperlink"
        :to="{
          name: 'investing.entity-overview',
          params: { entity_type: get(item, 'entity.entity_type'), entity_id: get(item, 'entity.id') },
        }"
      >
        {{ get(item, 'entity.name') }}
      </VLink>
    </template>
    <template #call_type="{ item }">
      <VBadge class="inline-flex items-center space-x-1" color="v-blue" size="xxs">
        <VIcon name="arrow_narrow_right" class="inline-block h-3 w-3" />
        <span>
          {{ type(item) }}
        </span>
      </VBadge>
    </template>
    <template #invoice.display_status="{ item }">
      <a
        v-if="item.invoice !== null"
        class="hyperlink"
        :class="get(item, 'invoice.display_status') === 'Past Due' ? '!text-red-400 !decoration-red-400/50' : ''"
        :href="`${rails_url()}/invoices/${get(item, 'invoice.id')}`"
      >
        {{ get(item, 'invoice.display_status') }}
      </a>
      <VButton
        v-else-if="isAdmin && type(item) !== 'transfer call'"
        :click="() => callStore.generateInvoice(item.id, props.investable_type, props.investable_id)"
        size="xs"
      >
        {{ capitalize(t('shared.generate invoice')) }}
      </VButton>
    </template>
    <template #actions="{ item }" v-if="isAdmin">
      <div class="flex items-center justify-end space-x-1.5" v-if="type(item) !== 'transfer call'">
        <ActionsMenu>
          <ActionsGroup>
            <ActionItem
              tag="RouterLink"
              :text="capitalize(t('shared.edit'))"
              :to="{ name: 'investing.capital-call.edit', params: { capital_call_id: get(item, 'transaction.id') } }"
            />
            <ActionItem
              :text="capitalize(t('shared.remove'))"
              @click="removeCall(item)"
              :loading="actionsMenu.actions.value.delete?.loading"
            />
            <ActionItem
              :text="capitalize(t('shared.send reminder'))"
              @click="sendReminder(item)"
              :loading="actionsMenu.actions.value.send_reminder?.loading"
              v-if="item.invoice !== null && !get(item, 'invoice.paid_at')"
            />
          </ActionsGroup>
        </ActionsMenu>
      </div>
    </template>
  </VTable>
</template>
