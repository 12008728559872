import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type Transfer = {
  id: number | null
  capital: Money
  management_fee: Money
  other_fee: Money
  investor_set_commitment_id: number | null
  investor_set_transaction_id: number | null
  commitment: {
    id: number | null
    capital: Money
    investor: {
      _cid: string
      id: number | null
      name: string | null
    }
    management_fee_percentage: number | null
    carried_interest_percentage: number | null
    commitment_remaining: Money
    ownership_percentage: number | null
  }
}

export type CapitalCall = {
  id: number | null
  prior: boolean
  date: string | null
  schedule_date: string | null
  notes: string | null
  wiring_instructions: string | null
  selected_capital_type: '%' | '$'
  currency: string | null
  entered_capital_amount: number | null
  entered_management_fees_amount: number | null
  entered_other_fees_amount: Money | null
  capital_call_transfers: Transfer[]
  fund_total_capital_committed: Money
  config: {
    is_new_capital_call: 'yes' | 'no'
    when_should_notification_be_sent: 'later' | 'now'
    include_capital: '1' | '2' | '3'
    allocate_management_fees: '1' | '2'
    selected_time_period: '1' | '2' | '3' | '4'
    selected_capital_type: '$' | '%'
    selected_management_fees_type: '$' | '%'
    selected_other_fees_type: '$' | '%'
  }
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type CapitalCallMap = Map<CID, CapitalCall>

export const useCapitalCallStore = defineStore('investing/capitalCallStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<CapitalCallMap>(new Map())
  const capital_calls = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchCapitalCall = async (investable_type, investable_id, capital_call_id = null) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/${investable_type}/${investable_id}/capital_call/${capital_call_id || 'new'}`,
    )
      .get()
      .json<{ data: CapitalCall[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    const capital_call = data.value.data
    addItem(items, { ...capital_call, _cid: capital_call.id })
  }

  const addCapitalCall = async (capital_call) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/${capital_call.investable_type}/${capital_call.investable_id}/capital_call/add`,
    )
      .post({ capital_call })
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const updateCapitalCall = async (capital_call) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/${capital_call.investable_type}/${capital_call.investable_id}/capital_call/update`,
    )
      .put({ capital_call })
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  const removeCapitalCall = async (investable_type, investable_id, capital_call_id) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/${investable_type}/${investable_id}/capital_call/${capital_call_id}/remove`,
    )
      .delete()
      .json<{}>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }
  }

  return {
    items,
    capital_calls,

    fetchCapitalCall,
    addCapitalCall,
    updateCapitalCall,
    removeCapitalCall,
  }
})
