import { ref, computed } from 'vue'

export const useActionsMenu = (actionKeys: string[]) => {
  const actionLoadings = ref(
    actionKeys.reduce(
      (acc, key) => {
        acc[key] = false
        return acc
      },
      {} as Record<string, boolean>,
    ),
  )
  const actions = computed(() =>
    actionKeys.reduce((acc, key) => {
      acc[key] = {
        loading: actionLoadings.value[key],
      }
      return acc
    }, {}),
  )

  const setLoading = (key: string, value: boolean) => {
    actionLoadings.value[key] = value
  }

  return { actions, setLoading }
}
